import { Component } from '@angular/core';

// NGRX
import { Store } from '@ngrx/store';
import { LoginComplete } from '../../store/actions/auth.actions';
import { State } from '../../../store/reducers/state';

@Component({
    selector: 'iam-root',
    template: ` <div></div> `,
    standalone: false
})
export class LoginCallbackComponent {
  constructor(private store: Store<State>) {
    this.store.dispatch(LoginComplete());
  }
}
