import { Component } from '@angular/core';

// NGRX
import { Store } from '@ngrx/store';
import { State } from '../../../store/reducers/state';
import { ActivationComplete } from '../../store/actions/activation.actions';

@Component({
    selector: 'iam-activation',
    template: ` <div></div> `,
    standalone: false
})
export class ActivationCallbackComponent {
  constructor(private store: Store<State>) {
    this.store.dispatch(ActivationComplete());
  }
}
